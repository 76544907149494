import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
    const el = this.element;
    if (el.hasAttribute('data-var')) {
      sessionStorage.setItem('var', el.getAttribute('data-var'));
    }
  }
  
  disconnect() {
  }

}