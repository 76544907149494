import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
  }
  
  disconnect() {
  }

  // toggleBanner = () => {
  //   if ($('#banner').css('display') == 'block' ) {
  //       $('#banner').css('display','none');
  //       $('#toggle button').html("+");
  //   }
  //   else {
  //     $('#banner').css('display','block');
  //     $('#toggle button').html("x");
  //   }
  // }
}