import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["searchfield"]

    initialize() {
    }

    connect() {
        if (document.body.clientWidth > 1009) {
            document.querySelector("#wrapper").classList.add("toggled");
        }

        const query = {}
        // Wurden in der URL Parameter übergeben?
        const vars = window.location.search.substring(1).split("&");
        vars.forEach((element) => {
            const pair = element.split("=")
            if (typeof query[pair[0]] === "undefined") {
                query[pair[0]] = decodeURIComponent(pair[1]);
            } else if (typeof query[pair[0]] === "string") {
                query[pair[0]] = [query[pair[0]], decodeURIComponent(pair[1])];
            } else {
                query[pair[0]].push(decodeURIComponent(pair[1]));
            }
        })

        // Werte aus dem localstorage
        if (query && !query.m && !query.s) {
            const m = sessionStorage.getItem('menu');
            if (m != "null") {
                query.m = m;
            }
            const s = sessionStorage.getItem('search');
            if (s != "null") {
                query.s = s;
            }
        }

        if (query && (query.t || query.m || query.s)) {
            if (query.t === "1") {
                // t==1: Menü ausklappen
                document.querySelector("#wrapper").classList.add("toggled");
            }

            if (query.s) {
                // Suchterm übergeben
                if (document.body.clientWidth < 1010) {
                    document.querySelector("#wrapper").classList.remove("toggled");
                }
                this.removeMenuHighlight();
                //toggleBanner();
                this.searchfieldTarget.value = query.s;
                this.search(query.s);
            }
            else if (query.m) {
                if (document.body.clientWidth < 1010) {
                    document.querySelector("#wrapper").classList.remove("toggled");
                }
                this.searchfieldTarget.value = "";
                sessionStorage.setItem('search', null);
                //toggleBanner();
                document.querySelectorAll(".sidebar-nav .link").forEach((el) => {
                    if (el.getAttribute('data-id') === query.m) {
                        el.classList.add("active");
                        document.querySelector("h1.page-header").textContent = el.innerHTML.trim().replace("&amp;", "&");
                        sessionStorage.setItem('menu', query.m);
                        this.searchForClass(el.getAttribute('data-filter'));
                    }
                    else {
                        el.classList.remove("active");
                    }
                });
            }
        }
        else {
            // Nichts in URL übergeben. Wir prüfen auf
            // data-Attribut.
            const m = document.querySelector("#search").getAttribute('data-menu');
            if (m) {
                this.searchForClass(m);
                document.querySelector("#wrapper").classList.add("toggled");
                this.searchfieldTarget.value = "";
                sessionStorage.setItem('search', null);
                //toggleBanner();
                document.querySelectorAll(".sidebar-nav .link").forEach((el) => {
                    if (el.getAttribute('data-filter') === m) {
                        el.classList.add("active");
                        document.querySelector("h1.page-header").textContent = el.innerHTML.trim().replace("&amp;", "&");
                        sessionStorage.setItem('menu', el.getAttribute('data-id'));
                    }
                    else {
                        el.classList.remove("active");
                    }
                });
            }
            else {
                // Bestseller als Startkategorie, wenn sonst nichts gewählt
                let startCategory = 's';
                if (this.getShopName() === 'vo') {
                    startCategory = 'a';
                }
                sessionStorage.setItem('search', null);
                this.searchForClass(startCategory);
                document.querySelectorAll(".sidebar-nav .link").forEach((el) => {
                    if (el.getAttribute('data-filter') === startCategory) {
                        el.classList.add("active");
                        document.querySelector("h1.page-header").textContent = el.innerHTML.trim().replace("&amp;", "&");
                        sessionStorage.setItem('menu', el.getAttribute('data-id'));
                    }
                    else {
                        el.classList.remove("active");
                    }
                });
            }
        }

        const variant = sessionStorage.getItem('var');
        if (variant && variant !== 'null') {
            if (typeof (history.pushState) == 'function') {
                history.pushState(null, null, '#var' + variant);
            }
            else {
                location.hash = '#var' + variant;
            }
            sessionStorage.setItem('var', null);
        }

        let hash = location.hash?.substring(1);
        if (hash) {
            // Es wurde eine Variante übergeben, wir scrollen zum entsprechenden Eintrag
            const elem = document.getElementById(hash);
            if (elem) {
                elem.scrollIntoView();
            }
        }
    }

    disconnect() {
    }

    searchForTerm = (term) => {
        document.querySelectorAll('.grid .item').forEach((el) => {
            let found = false;
            const termRegExp = new RegExp(term, "i");

            const nameElement = el.querySelector(".name");
            const subElement = el.querySelector(".sub");
            const sElement = el.querySelector(".s");
            if (nameElement && nameElement.textContent && termRegExp.test(nameElement.textContent)) {
                found = true;
            }
            else if (subElement && subElement.textContent && termRegExp.test(subElement.textContent)) {
                found = true;
            }
            else if (sElement && sElement.textContent && termRegExp.test(sElement.textContent)) {
                found = true;
            }

            if (el.classList.contains("ar")) {
                found = false;
            }

            if (found === true) {
                el.style.display = "block";
            }
            else {
                el.style.display = "none";
            }
        });
    }

    searchForClass = (klass) => {
        document.querySelectorAll('.grid .item').forEach((el) => {
            if (el.classList.contains(klass)) {
                el.style.display = "block";
            }
            else {
                el.style.display = "none";
            }
        });
    }

    search = (searchText) => {
        if (searchText == "") {
            document.querySelector("h1.page-header").textContent = "";
            sessionStorage.setItem('search', null);
        }
        else {
            document.querySelector("h1.page-header").textContent = "Suche: " + searchText;
            sessionStorage.setItem('search', searchText);
        }
        sessionStorage.setItem('menu', null);
        this.searchForTerm(searchText);
        window.scrollTo(0, 0);
    };

    searchfieldChanged = (event) => {
        event.preventDefault();
        this.removeMenuHighlight()
        const searchText = this.searchfieldTarget.value;
        if (event.type == 'submit' || event.code == "Enter" || event.keyCode == 13 || event.which == 13) {
            //this.searchfieldTarget.value = ""
            //this.search("");
        }
        else {
            this.search(searchText);
        }
    }

    removeMenuHighlight = () => {
        document.querySelectorAll(".sidebar-nav .link").forEach((el) => {
            el.classList.remove("active");
        });
    }

    menuItemClicked = (data) => {
        const m = data.detail.target;
        this.searchForClass(m);
        document.querySelector("#wrapper").classList.add("toggled");
        this.searchfieldTarget.value = "";
        sessionStorage.setItem('search', null);
        //toggleBanner();
        document.querySelectorAll(".sidebar-nav .link").forEach((el) => {
            if (el.getAttribute('data-filter') === m) {
                el.classList.add("active");
                document.querySelector("h1.page-header").textContent = el.innerHTML.trim().replace("&amp;", "&");
                sessionStorage.setItem('menu', el.getAttribute('data-id'));
            }
            else {
                el.classList.remove("active");
            }
        });
        if (document.body.clientWidth < 1010) {
            document.querySelector("#wrapper").classList.remove("toggled");
        }

        window.scrollTo(0, 0);
    }

    emptySearchFieldClicked = (event) => {
        event.preventDefault();
        this.removeMenuHighlight()
        this.searchfieldTarget.value = "";
        this.search("");
    }

    getShopName = () => {
        return document.querySelector("#sform").getAttribute("shop");
    }
}