import { Controller } from 'stimulus';

export default class extends Controller {
    
    initialize() {
    }

    connect() {
        window.addEventListener('scroll', this.scrollEvent, { passive: true});
    }
  
    disconnect() {
        window.removeEventListener('scroll', this.scrollEvent);
    }

    scrollEvent = (event) => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTo;
        if (scrollTop > 400) {
            // anzeigen
            this.element.classList.add("visible");
        }
        else {
            // entfernen
            this.element.classList.remove("visible");
        }
    } 

    scrollToTop(event) {
        window.scrollTo(0,0)
    }
}