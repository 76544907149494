import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
  }
  
  disconnect() {
  }

  toggleMenu = (event) => {
    event.preventDefault();
    const wrapper = document.querySelector("#wrapper");
    wrapper.classList.toggle("toggled");
  }

  menuItemClicked = (event) => {
    event.preventDefault();
    //console.log(event)
    // Event für den Search Controller schicken
    const t = event.target.getAttribute("data-filter")
    const menuItemClickedEvent = new CustomEvent("menuItemClicked", {
      detail: {
        target: t
      }
    });
    window.dispatchEvent(menuItemClickedEvent)
  }
}