import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'image', 'root' ];
  static values = {
    rootMargin: String,
    threshold: Array
  }

  initialize() {
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this)
  }

  connect() {
    this.observer = new IntersectionObserver(this.intersectionObserverCallback, this.intersectionObserverOptions);
    this.imageTargets.forEach(item => this.observer.observe(item));
  }
  
  disconnect() {
    this.imageTargets.forEach(item => this.observer.unobserve(item))
  }

  intersectionObserverCallback (entries, observer) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > this.threshold) {
        entry.target.src = entry.target.dataset.src;
        observer.unobserve(entry.target);
      }
    })
  }

  get rootMargin() {
    return this.hasRootMarginValue ? this.rootMarginValue : "0px";
  }

  get root() {
    return this.hasRootTarget ? this.rootTarget : null;
  }

  get threshold() {
    return this.hasThresHoldValue ? this.thresholdValue : [ 0.1 ];
  }

  get intersectionObserverOptions () {
    return {
      threshold: this.threshold,
      rootMargin: this.rootMargin
    }
  }

}