import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
  }
  
  disconnect() {
  }

  showModal = (event) => {
    event.preventDefault();
    //console.log("modal#showModal");
    //console.log(this.element.id, event.detail)
    if (this.element.id === event.detail[0]) {
      this.element.setAttribute('origin', event.detail[1]);
      this.element.classList.remove("fade");
      this.element.classList.add("show");
      document.querySelector("body").classList.add("modal-open");
    }
  }

  hideModal = (event) => {
    this.element.classList.remove("show");
    this.element.classList.add("fade");
    document.querySelector("body").classList.remove("modal-open");

}

  submitForm = (event) => {
    //console.log("modal#submitForm");
    let formId = event.target.getAttribute("data-form");
    //console.log(formId);
    if (formId == "frm_other") {
      // Wir haben generische Versandhinweise angezeigt
      // und müssen jetzt das eigentliche Zielformular
      // aus dem origin-Attribut des Modals holen.
      const modal = document.getElementById("other");
      formId = "frm_" + modal.getAttribute("origin");
    }
    //console.log(formId);
    const form = document.querySelector("form." + formId);
    //console.log(form);
    this.element.classList.remove("show");
    this.element.classList.add("fade");
    document.querySelector("body").classList.remove("modal-open");
    form.submit();
  }
}
