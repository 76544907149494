import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
    //console.log("Modalmaster")
  }
  
  disconnect() {
  }

  showModal(event) {
    event.preventDefault();
    //console.log("modalmaster#showModal");
    const origin = event.target.getAttribute('data-target');
    let modalId = event.target.getAttribute('data-target');
    //console.log("origin", origin);
    if (modalId == "brief" || modalId == "wpi" || modalId == "wpipremium" || modalId == "paketpremium") {
      modalId = "other";
    }
    //console.log("modalID", modalId);
    window.dispatchEvent(new CustomEvent("show-modal", {detail: [modalId, origin]}));
  }
}
