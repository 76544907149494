import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
  }
  
  disconnect() {
  }

  swap = (event) => {
      event.preventDefault();
      const v1 = document.querySelector('#shippingaddress_address_line_1').value;
      document.querySelector('#shippingaddress_address_line_1').value = document.querySelector('#shippingaddress_address_line_2').value;
      document.querySelector('#shippingaddress_address_line_2').value = v1;
  }

  moveleft = (event) => {
    event.preventDefault();
    document.querySelector('#shippingaddress_address_line_1').value = document.querySelector('#shippingaddress_address_line_1').value + " " + document.querySelector('#shippingaddress_address_line_2').value;
    document.querySelector('#shippingaddress_address_line_2').value = "";
  }

  togglecheckboxes = (event) => {
    event.preventDefault();
    document.querySelectorAll('.checkbox').forEach(element => {
        if (element.hasAttribute("checked")) {
            element.removeAttribute("checked");
        }
        else {
            element.setAttribute('checked', true);            
        }
    })
  }
}