import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
  }

  disconnect() {
  }

  toggle = (event) => {
    const targetSelector = this.element.getAttribute("data-target");
    const target = document.querySelector(targetSelector);
    const button = document.querySelector('#billingButton');
    if (target.classList.contains("collapse")) {
      target.classList.remove("collapse");
      button.innerHTML = "-";


      document.querySelector('#billing_address_title_id').setAttribute('required', true);
      document.querySelector('#billing_address_first_name').setAttribute('required', true);
      document.querySelector('#billing_address_last_name').setAttribute('required', true);
      document.querySelector('#billing_address_address_line_1').setAttribute('required', true);
      document.querySelector('#billing_address_postalcode').setAttribute('required', true);
      document.querySelector('#billing_address_city').setAttribute('required', true);
      document.querySelector('#billing_address_country_id').setAttribute('required', true);
    }
    else {
      target.classList.add("collapse")
      button.innerHTML = "+";
      document.querySelector('#billing_address_title_id').value = "keine";
      document.querySelector('#billing_address_first_name').value = "";
      document.querySelector('#billing_address_last_name').value = "";
      document.querySelector('#billing_address_care_of').value = "";
      document.querySelector('#billing_address_address_line_1').value = "";
      document.querySelector('#billing_address_address_line_2').value = "";
      document.querySelector('#billing_address_postalcode').value = "";
      document.querySelector('#billing_address_city').value = "";
      document.querySelector('#billing_address_country_id').value = "1";

      document.querySelector('#billing_address_title_id').removeAttribute('required');
      document.querySelector('#billing_address_first_name').removeAttribute('required');
      document.querySelector('#billing_address_last_name').removeAttribute('required');
      document.querySelector('#billing_address_address_line_1').removeAttribute('required');
      document.querySelector('#billing_address_postalcode').removeAttribute('required');
      document.querySelector('#billing_address_city').removeAttribute('required');
      document.querySelector('#billing_address_country_id').removeAttribute('required');
    }
  }

}
