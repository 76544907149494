import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
  }

  connect() {
    if (!document.querySelector('#shipping_address_address_line_2').value.indexOf('Packstation')) {
        this.setPackstationActive();
    }
    else if (!document.querySelector('#shipping_address_address_line_2').value.indexOf('Postfiliale')) {
        this.setPostfilialeActive();
    }
    else {
        this.setHausanschriftActive();
    }
  }
  
  disconnect() {
  }

  buttonClicked = (event) => {
    const id = event.target.id;
      if (id === "hausanschrift") {
        this.setHausanschriftActive();
      }
      else if (id === "packstation") {
        this.setPackstationActive();
      }
      else if (id === "postfiliale") {
        this.setPostfilialeActive();
      }
    }

    setHausanschriftActive = () => {
        document.querySelector("#hausanschrift").classList.add("active");
        document.querySelector("#packstation")?.classList.remove("active");
        document.querySelector("#postfiliale")?.classList.remove("active");
      
        document.querySelector('#shipping_lastname2_div').style.display = "block";

        document.querySelector('#shipping_address_line1_div').style.display = "block";
        document.querySelector('#shipping_address_line1_div input').setAttribute('required', true);

        if (!document.querySelector('#shipping_address_line2_div').classList.contains("notallowed")) {
          document.querySelector('#shipping_address_line2_div').style.display = "block";
        } else {
          document.querySelector('#shipping_address_line2_div').style.display = "none";
        }
        document.querySelector('#shipping_postnummer_div').style.display = "none";
        document.querySelector('#shipping_postnummer_div input').value = "";
        document.querySelector('#shipping_postnummer_div input').removeAttribute('required');

        document.querySelector('#shipping_packstation_div').style.display = "none";
        document.querySelector('#shipping_packstation_div input').value = "";
        document.querySelector('#shipping_packstation_div input').removeAttribute('required');

        document.querySelector('#shipping_postfiliale_div').style.display = "none";
        document.querySelector('#shipping_postfiliale_div input').value = "";
        document.querySelector('#shipping_postfiliale_div input').removeAttribute('required');

        document.querySelector('#shippingLastNameHelp').style.display = "none";
        document.querySelector('#shippingAddressHelp').style.display = "block";
    }

    setPackstationActive = () => {
        document.querySelector("#hausanschrift").classList.remove("active");
        document.querySelector("#packstation")?.classList.add("active");
        document.querySelector("#postfiliale")?.classList.remove("active");

        document.querySelector('#shipping_lastname2_div').style.display = "none";
        document.querySelector('#shipping_lastname2_div input').value = "";

        document.querySelector('#shipping_address_line1_div').style.display = "none";
        document.querySelector('#shipping_address_line1_div input').value = "";
        document.querySelector('#shipping_address_line1_div input').removeAttribute('required');
      
        document.querySelector('#shipping_address_line2_div').style.display = "none";
        document.querySelector('#shipping_address_line2_div input').value = "";

        document.querySelector('#shipping_postnummer_div').style.display = "block";
        document.querySelector('#shipping_postnummer_div input').setAttribute('required', true);

        document.querySelector('#shipping_packstation_div').style.display = "block";
        document.querySelector('#shipping_packstation_div input').setAttribute('required', true);

        document.querySelector('#shipping_postfiliale_div').style.display = "none";
        document.querySelector('#shipping_postfiliale_div input').value = "";
        document.querySelector('#shipping_postfiliale_div input').removeAttribute('required');
      
        document.querySelector('#shippingLastNameHelp').style.display = "block";
        document.querySelector('#shippingAddressHelp').style.display = "none";
    }

    setPostfilialeActive = () => {
        document.querySelector("#hausanschrift").classList.remove("active");
        document.querySelector("#packstation")?.classList.remove("active");
        document.querySelector("#postfiliale")?.classList.add("active");

        document.querySelector('#shipping_lastname2_div').style.display = "none";
        document.querySelector('#shipping_lastname2_div input').value = "";
      
        document.querySelector('#shipping_address_line1_div').style.display = "none";
        document.querySelector('#shipping_address_line1_div input').value = "";
        document.querySelector('#shipping_address_line1_div input').removeAttribute('required');
      
        document.querySelector('#shipping_address_line2_div').style.display = "none";
        document.querySelector('#shipping_address_line2_div input').value = "";

        document.querySelector('#shipping_postnummer_div').style.display = "block";
        document.querySelector('#shipping_postnummer_div input').setAttribute('required', true);
     
        document.querySelector('#shipping_packstation_div').style.display = "none";
        document.querySelector('#shipping_packstation_div input').value = "";
        document.querySelector('#shipping_packstation_div input').removeAttribute('required');

        document.querySelector('#shipping_postfiliale_div').style.display = "block";
        document.querySelector('#shipping_postfiliale_div input').setAttribute('required', true);
 
        document.querySelector('#shippingLastNameHelp').style.display = "block";
        document.querySelector('#shippingAddressHelp').style.display = "none";
    }
}